const toastr = require('toastr/build/toastr.min');
const REPORT_REVIEW_MODAL_ID = 'reportReview';
const REPORT_REVIEW_FORM_ID = 'reportReviewForm';
let reportProcessing = false;
$(document).on('click', '.report', function (event) {
    event.preventDefault();
    let errorOverlap = $(this).data('error-overlap');

    if (reportProcessing) {
        toastr.error(errorOverlap);
        return;
    }
    reportProcessing = true;

    let route = $(this).data('route');
    let errorDefault = $(this).data('error-default');

    if (!!route) {
        let reportReviewForm = $('#' + REPORT_REVIEW_FORM_ID);
        reportReviewForm.attr('action', route);
        reportReviewForm.attr('data-error-default', errorDefault);
        $('#' + REPORT_REVIEW_MODAL_ID).modal('show');
    } else {
        toastr.error(errorDefault);
        reportProcessing = false;
    }
});

$(document).on('submit', '#'+ REPORT_REVIEW_FORM_ID, function (event) {
    event.preventDefault();
    let reportReviewForm = $(this);

    let route = reportReviewForm.attr('action');
    let errorDefault = reportReviewForm.data('error-default');

    if (!!route) {
        let content = $('#' + REPORT_REVIEW_FORM_ID + 'Content').val();
        let contentError = reportReviewForm.data('error-content');
        if (!content || content.length < 1) {
            toastr.error(contentError);
            reportProcessing = false;
            return;
        }
        $.post(route, {content: content})
            .done(function (response) {
                if (response.success == 1) {
                    reportReviewForm.attr('action', '');
                    reportReviewForm.attr('data-error-default', '');
                    $('#' + REPORT_REVIEW_FORM_ID + 'Content').val('');
                    $('#' + REPORT_REVIEW_MODAL_ID).modal('hide');
                    toastr.success(response.message);
                } else if (response.success == 0) {
                    toastr.error(response.message);
                } else {
                    toastr.error(response.message ? response.message : errorDefault);
                }
                reportProcessing = false;
            })
            .fail(function (xhr, status, error) {
                toastr.error(error);
                reportProcessing = false;
            });
    } else {
        toastr.error(errorDefault);
        reportProcessing = false;
    }
});

$('#' + REPORT_REVIEW_MODAL_ID).on('hide.bs.modal', function (e) {
    $('#' + REPORT_REVIEW_FORM_ID).attr('action', '');
    $('#' + REPORT_REVIEW_FORM_ID).attr('data-error-default', '');
    $('#' + REPORT_REVIEW_FORM_ID + 'Content').val('');
    reportProcessing = false;
})
