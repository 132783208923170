const toastr = require('toastr/build/toastr.min');
let voteProcessing = false;
$(document).on('click', '.review-vote-block .vote-action', function (event) {
    event.preventDefault();
    let reviewVoteBlock = $(this).parent();
    let defaultError = $(reviewVoteBlock).data('error-default');
    let overlapError = $(reviewVoteBlock).data('error-overlap');
    if (voteProcessing) {
        toastr.error(overlapError);
        return;
    }
    voteProcessing = true;
    let voteActionElement = $(this);
    if (!!reviewVoteBlock) {
        let route = $(reviewVoteBlock).data('route');
        let vote = voteActionElement.data('vote');
        if (!!route && !!vote) {
            $.post(route, {vote: vote})
                .done(function (response) {
                    if (response.success == 1) {
                        $(reviewVoteBlock).find('.votes-up .vote-count').text(response.votes_up);
                        $(reviewVoteBlock).find('.votes-down .vote-count').text(response.votes_down);
                        if (voteActionElement.hasClass('fa-thumbs-up') || voteActionElement.hasClass('fa-thumbs-o-up')) {
                            if (voteActionElement.hasClass('fa-thumbs-up')) {
                                voteActionElement.removeClass('fa-thumbs-up');
                                voteActionElement.addClass('fa-thumbs-o-up');
                            } else if (voteActionElement.hasClass('fa-thumbs-o-up')) {
                                voteActionElement.removeClass('fa-thumbs-o-up');
                                voteActionElement.addClass('fa-thumbs-up');
                            }
                            if ($(reviewVoteBlock).find('.votes-down').hasClass('fa-thumbs-down')) {
                                $(reviewVoteBlock).find('.votes-down')
                                    .removeClass('fa-thumbs-down')
                                    .addClass('fa-thumbs-o-down');
                            }
                        }
                        if (voteActionElement.hasClass('fa-thumbs-down') || voteActionElement.hasClass('fa-thumbs-o-down')) {
                            if (voteActionElement.hasClass('fa-thumbs-down')) {
                                voteActionElement.removeClass('fa-thumbs-down');
                                voteActionElement.addClass('fa-thumbs-o-down');
                            } else if (voteActionElement.hasClass('fa-thumbs-o-down')) {
                                voteActionElement.removeClass('fa-thumbs-o-down');
                                voteActionElement.addClass('fa-thumbs-down');
                            }
                            if ($(reviewVoteBlock).find('.votes-up').hasClass('fa-thumbs-up')) {
                                $(reviewVoteBlock).find('.votes-up')
                                    .removeClass('fa-thumbs-up')
                                    .addClass('fa-thumbs-o-up');
                            }
                        }
                        toastr.success(response.message);
                    } else if (response.success == 0) {
                        toastr.error(response.message);
                    } else {
                        toastr.error(response.message ? response.message : defaultError);
                    }
                    voteProcessing = false;
                })
                .fail(function (xhr, status, error) {
                    toastr.error(error);
                    voteProcessing = false;
                });
        } else {
            toastr.error(defaultError);
            voteProcessing = false;
        }
    } else {
        toastr.error(defaultError);
        voteProcessing = false;
    }
});
