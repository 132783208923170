$(document).on('click', '.server-chronicles-select .add-new', function (event) {
    event.preventDefault();
    let templateItem = $(this).parent().parent().find('.template-item').clone();
    if (!!templateItem) {
        $(templateItem).removeClass('template-item');
        $(templateItem).removeClass('preserve');
        $(templateItem).removeClass('d-none');

        let nameKey = 0;
        let existingItems = $(this).parent().parent().find('.list-group-item:not(.preserve)');
        for (let i = 0, l = existingItems.length; i < l; i++) {
            let existingNameKey = parseInt($(existingItems[i]).data('key'));
            if (existingNameKey >= nameKey) {
                nameKey = existingNameKey + 1;
            }
        }

        $(templateItem).data('key', nameKey);
        $(templateItem).find('select').attr('name', `chronicles[${nameKey}][chronicle_id]`);
        $(templateItem).find('input[type=number]').attr('name', `chronicles[${nameKey}][rate]`);
        $(templateItem).find('input[type=text]').attr('name', `chronicles[${nameKey}][name]`);

        $(this).parent().parent().find('.prepend-before-item').before($(templateItem));
    } else {
        alert('Error');
    }
});

$(document).on('click', '.server-chronicles-select .delete-item', function (event) {
    event.preventDefault();
    $(this).parent().parent().parent().remove();
});
