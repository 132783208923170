require('./bootstrap');
require('jquery-ui-dist/jquery-ui.js');
require('bootstrap-datepicker/dist/js/bootstrap-datepicker');
require('bootstrap-datepicker/dist/locales/bootstrap-datepicker.ru.min');
require('lightbox2/dist/js/lightbox.min');

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});
require('./server-chronicles-select');
require('./vote-review');
require('./report-review');

$('.datepicker').datepicker({
    language: $('html').attr('lang'),
    changeMonth: true,
    changeYear: true
});

let tinyMceBBCodeSettings = {
    branding: false,
    menubar: false,
    selector: '.bbcode',
    height: 500,
    plugins: 'bbcode',
    toolbar_mode: 'wrap',
    toolbar: 'undo redo | styleselect | bold italic underline | bullist numlist outdent indent | removeformat',
    bbcode_dialect: 'punbb'
};

let tinyMceSettings = {
    branding: false,
    selector: '.tinymce',
    height: 500,
    plugins: 'code link image lists',
    toolbar_mode: 'wrap',
    toolbar: 'undo redo | styleselect | bold italic underline | forecolor backcolor | alignleft aligncenter alignright alignjustify | ' +
        'bullist numlist outdent indent | link image | code removeformat'
};

if (localStorage.getItem('darkMode')) {
    $('body').addClass('dark-theme');
    $('#darkModeSwitch').prop('checked', true);
    tinyMceBBCodeSettings.skin = 'dark-custom';
    tinyMceBBCodeSettings.content_css = 'dark-custom';
    tinyMceSettings.skin = 'dark-custom';
    tinyMceSettings.content_css = 'dark-custom';
}
tinymce.init(tinyMceBBCodeSettings);
tinymce.init(tinyMceSettings);


$(document).on('change', '#darkModeSwitch', function (event) {
    if ($(this).is(':checked')) {
        localStorage.setItem('darkMode', 1);
        $('body').addClass('dark-theme');
        $('#mce-u0').attr('href', '/vendor/tinymce/skins/ui/dark-custom/skin.min.css');
        $('.tox-edit-area__iframe').contents().find('#mce-u0').attr('href', '/vendor/tinymce/skins/ui/dark-custom/skin.min.css');
        $('.tox-edit-area__iframe').contents().find('#mce-u1').attr('href', '/vendor/tinymce/skins/content/dark-custom/content.min.css');
    } else {
        localStorage.removeItem('darkMode');
        $('body').removeClass('dark-theme');
        $('#mce-u0').attr('href', '/vendor/tinymce/skins/ui/oxide/skin.min.css');
        $('.tox-edit-area__iframe').contents().find('#mce-u0').attr('href', '/vendor/tinymce/skins/ui/oxide/skin.min.css');
        $('.tox-edit-area__iframe').contents().find('#mce-u1').attr('href', '/vendor/tinymce/skins/content/default/content.min.css');
    }
});

$(document).on('click', '.confirm-submit', function (event) {
    event.preventDefault();
    let formId = $(this).data('form');
    let confirmMessage = $(this).data('message');

    if (!!formId && !!confirmMessage) {
        if (confirm(confirmMessage)) {
            $('#' + formId).submit();
        }
    } else {
        alert('Error');
    }
});

$('.modal[data-show-immediately="1"]').modal('show');

$(document).on('click', '.submit-form', function (event) {
    event.preventDefault();

    let formId = $(this).data('submit-form');

    if (!!formId) {
        $('#' + formId).submit();
    }
});
